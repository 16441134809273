<template>
	<div class="bulk_box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>付款订单</span></div>

			<!-- <el-tabs v-model="orderStatus" @tab-click="handleClick">
				<el-tab-pane label="全部订单" name="all"></el-tab-pane>
				<el-tab-pane label="待支付" name="ORDER_ENSURE_CREATE"></el-tab-pane>
				<el-tab-pane label="待签订" name="WAIT_ORDER_SIGN"></el-tab-pane>
				<el-tab-pane label="合同执行" name="ORDER_CONTRACTEXECUTION"></el-tab-pane>
				<el-tab-pane label="已完成" name="ORDER_COMPLETE"></el-tab-pane>
			</el-tabs> -->
			<div class="search_area">
				<div>
					订单编号：
					<input type="text" placeholder="请输入订单编号" v-model="order_no">
					支付时间：
					<el-date-picker @change="changeTimeLimit" v-model="date_limit" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<div class="btn" @click="refresh">搜索</div>
				</div>
			</div>
			<div v-loading="loading">
				<nav>
					<li>订单内容</li>
					<li>合同金额</li>
					<li>期数</li>
					<li>逾期状态</li>
					<li>订单状态</li>
					<li>操作</li>
				</nav>
				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head">
							<span class="create-time">合同时间：{{ $util.timeStampTurnTime(orderItem.plan_time) }}</span>
							<!-- <router-link :to="'/shop-' + orderItem.site_id" target="_blank">{{ orderItem.site_name }}</router-link> -->
							<!-- <span class="order-type">{{ orderItem.order_type_name }}</span> -->
							<span class="order-type create-time">下单人：{{ orderItem.nickname }}</span>
							<span class="order-type create-time" v-if="orderItem.largePaymentNo">交易流水号：{{ orderItem.largePaymentNo }}</span>
							<span class="order-type create-time" v-if="orderItem.pay_type">支付方式：{{ orderItem.pay_type }}</span>
						</div>
						<ul >
							<li>
								<span class="order-no">订单号：{{ orderItem.order_no }}</span></br>
								<span class="order-no">合同号：{{ orderItem.plan_out_trade_no }}</span>
							</li>
							<li>
								{{orderItem.plan_amount}}
							</li>
							
							<li>
								第{{orderItem.periods}}期
							</li>
							<li>
								{{orderItem.is_overdue ? '已逾期':''}}
							</li>
							
								<li>
									<span class="ns-text-color">{{ orderItem.pay_status_name }}</span>
									<!-- <el-link :underline="false" @click="orderDetail(orderItem)">订单详情</el-link> -->
								</li>
								<li>
									<el-button
										type="primary"
										size="mini" :plain="true"
										@click="orderDetail(orderItem)">
										订单详情
									</el-button>
									<template v-if="orderItem.is_pay==1">
										<el-button
											type="primary"
											size="mini" :plain="true"
											@click="toPay(orderItem)"
										>
											去支付
										</el-button>
									</template>
									<!-- <template v-if="orderItem.action && orderItem.action.length > 0">
										<el-button type="primary" size="mini" plain v-if="orderItem.is_evaluate == 1" @click="operation('memberOrderEvaluation', orderItem)">
											<template v-if="orderItem.evaluate_status == 0">
												评价
											</template>
											<template v-else-if="orderItem.evaluate_status == 1">
												追评
											</template>
										</el-button>
										<el-button
											type="primary"
											size="mini"
											:plain="operationItem.action == 'orderPay' ? false : true"
											v-for="(operationItem, operationIndex) in orderItem.action"
											:key="operationIndex"
											@click="operation(operationItem.action, orderItem)"
										>
											{{ operationItem.title }}
										</el-button>
										
									</template>

									<template class="order-operation" v-else-if="orderItem.action && orderItem.action.length == 0 && orderItem.is_evaluate == 1">
										<el-button type="primary" size="mini" plain v-if="orderItem.is_evaluate == 1" @click="operation('memberOrderEvaluation', orderItem)">
											<template v-if="orderItem.evaluate_status == 0">
												评价
											</template>
											<template v-else-if="orderItem.evaluate_status == 1">
												追评
											</template>
										</el-button>
									</template> -->
								</li>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关订单</div>
			</div>
			<div class="pager">
				<el-pagination 
					background 
					:pager-count="5" 
					:total="total" 
					prev-text="上一页" 
					next-text="下一页" 
					:current-page.sync="currentPage" 
					:page-size.sync="pageSize" 
					@size-change="handlePageSizeChange" 
					@current-change="handleCurrentPageChange" 
					hide-on-single-page
				></el-pagination>
			</div>
		</el-card>
		<el-dialog v-if="show_pay_info && show_pay_info.value" :visible.sync="upload_payment" :show-close="true" class="add_dialog">
			<div class="tab-list">
				<div class="tab-item" v-for="(item,index) in tabList" :key="index" :class="index == tabIndex ? 'active-tab' : ''" @click="tabIndex = index">
					<div class="title">
						{{item.name}}
					</div>
					<div class="" :class="index == tabIndex ? 'border-box' : ''">
					</div>
				</div>
			</div>
			<div class="add_content" v-if="tabIndex == 1">
				<div class="condition">
					<div class="condition_item">
						<div class="left">
							<div class="name">应付时间：</div>
						</div>
						<div class="right">{{ $util.timeStampTurnTime(show_pay_info.plan_time).split(' ')[0]}}</div>
					</div>
					<div class="condition_item">
						<div class="left">
							<div class="name">应付金额：</div>
						</div>
						<div class="right heli-tit">{{show_pay_info.plan_amount}}</div>
					</div>
					<div class="condition_item">
						<div class="left">
							<div class="name">支付期数：</div>
						</div>
						<div class="right">{{show_pay_info.periods}}</div>
					</div>
					<div class="condition_item">
						<div class="left">
							<div class="name">开户名称：</div>
						</div>
						<div class="right">{{show_pay_info.value.account_name}}</div>
					</div>
					<div class="condition_item">
						<div class="left">
							<div class="name">开户银行：</div>
						</div>
						<div class="right">{{show_pay_info.value.bank_name}}</div>
					</div>
					<div class="condition_item">
						<div class="left">
							<div class="name">银行账户：</div>
						</div>
						<div class="right">{{show_pay_info.value.bank_account}}</div>
					</div>
					<div class="condition_item upload">
						<div class="left">
							*<div class="name">上传支付凭证：</div>
						</div>
						<div class="right">
							<el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleAvatarSuccess" class="upload-img">
								<div class="img-wrap" v-if="upload_img"><img :src="$img(upload_img)" /></div>
								<div class="add_img" v-else>
									<i class="el-icon-plus"></i>
									上传图片
								</div>
							</el-upload>
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="btn save" @click="uploadPayInfo()">上传</div>
					<div class="btn" @click="upload_payment = false">返回</div>
				</div>
			</div>
			<div class="add_content" v-else>
				<div class="condition">
					<div class="condition_item" v-if="show_pay_info.plan_amount">
						<div class="left">
							<div class="name">订单金额：</div>
						</div>
						<div class="right">
							<span ref="copyMoney" class="heli-tit">{{show_pay_info.plan_amount}}</span>
							<span class="copy-btn" @click="copyAccount('copyMoney')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="bankList">
						<div class="left">
							<div class="name">选择银行：</div>
						</div>
						<div class="right">
							<el-select v-model="bankvalue" placeholder="请选择" :value-key="bankKey">
								<el-option
								  v-for="item in bankList"
								  :key="item"
								  :label="bankList[item]"
								  :value="item">
								</el-option>
							  </el-select>
						</div>
					</div>
					
					
					
					
					
					<!-- <div class="condition_item" v-if="heliPayInfo.order_no">
						<div class="left">
							<div class="name">商户订单号：</div>
						</div>
						<div class="right">{{heliPayInfo.order_no}}</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.order_amount">
						<div class="left">
							<div class="name">订单金额：</div>
						</div>
						<div class="right">
							<span ref="copyMoney" class="heli-tit">{{heliPayInfo.order_amount}}</span>
							<span class="copy-btn" @click="copyAccount('copyMoney')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.large_payment_no">
						<div class="left">
							<div class="name">收款方账号：</div>
						</div>
						<div class="right" >
							<span ref="copysNo">{{heliPayInfo.large_payment_no}}</span>
							<span class="copy-btn" @click="copyAccount('copysNo')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.receiver_name">
						<div class="left">
							<div class="name">收款方名称：</div>
						</div>
						<div class="right" >
							<span ref="copyName">{{heliPayInfo.receiver_name}}</span>
							<span class="copy-btn" @click="copyAccount('copyName')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.opening_location">
						<div class="left">
							<div class="name">开户地：</div>
						</div>
						<div class="right">
							<span ref="copyAddress">{{heliPayInfo.opening_location}}</span>
							<span class="copy-btn" @click="copyAccount('copyAddress')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.branch_branches">
						<div class="left">
							<div class="name">分支行：</div>
						</div>
						<div class="right">
							<span ref="copyFen">{{heliPayInfo.branch_branches}}</span>
							<span class="copy-btn" @click="copyAccount('copyFen')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.branch_name">
						<div class="left">
							<div class="name">银行名称：</div>
						</div>
						<div class="right">
							<span ref="copyBankName">{{heliPayInfo.branch_name}}</span>
							<span class="copy-btn" @click="copyAccount('copyBankName')">点击复制</span>
						</div>
					</div>
					<div class="condition_item" v-if="heliPayInfo.complete_date">
						<div class="left">
							<div class="name">支付时间：</div>
						</div>
						<div class="right">
							<span ref="copyBankName">{{$timeStampTurnTime(heliPayInfo.complete_date)}}</span>
							<span class="copy-btn" @click="copyAccount('copyBankName')">点击复制</span>
						</div>
					</div>
					<div class="condition_item upload ">
						<div class="left">
							<div class="name heli-tit">请复制银行转账所需信息，进行转账流程</div>
						</div>
					</div> -->
					<div class="bottom_btn">
						<div class="btn"  @click="goPay()">
							去支付
						</div>
						<div class="btn" @click="upload_payment = false">我已支付<div class="tips">(请耐心等待审核)</div></div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/utils/config';
import { OrderPayplanPageList, OrderpayConfig,savePaymentVoucher,getPayinfo,onlineinterface,payType } from '@/api/bulkOrder';
import orderMethod from '@/utils/orderMethod';
export default {
	name: 'order_list',
	components: {},
	data: () => {
		return {
			tabIndex:0,
			tabList:[
				{
					name:'合利宝支付',
				},
				{
					name:'线下支付'
				}
			],
			bankKey:'',
			bankvalue:'平安银行',
			 bankList: [],
			loading: true,
			orderList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			yes: true,
			start_time:'',
			end_time:'',
			order_no:'',
			name:'',
			mobile:'',
			date_limit:'',
			show_pay_info:{},
			uploadActionUrl: Config.baseUrl + '/api/upload/headimg',
			upload_img:'',
			upload_payment:false,
			upload_plan_id:'',
			orderStatus: 'all',
			textToCopy: '' ,
			heliPayInfo:{},
			orderClickINfo:{}
		};
	},
	mixins: [orderMethod],
	created() {
		this.orderStatus = this.$route.query.status || 'all';
		this.getOrderList();
		this.getpayType()
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	watch:{
		upload_payment(){
			this.tabIndex = 0
		}
	},
	methods: {
		goPay(){
			console.log()
			console.log(this.heliPayInfo)
			for(let i in this.bankList){
				if(this.bankvalue == this.bankList[i]){
					this.bankKey = i
				}
			}
			onlineinterface({
				P5_bankId:this.bankKey,
				plan_id:this.orderClickINfo.plan_id
			})
			.then(res=>{
				if(res.code >= 0){
					window.open(Config.baseUrl + res.data.pay_url, '_blank')
				}else{
					this.$message.error(res.message);
				}
			})
			.catch((res)=>{
				this.$message.error(res.message);
			})
			console.log(this.bankKey)
		},
		getpayType(){
			payType()
			.then(res=>{
				this.bankList = res
			})
			.catch((e)=>{
				this.bankList = e
			})
		},
		 copyAccount(str) {
		  const copyElement = this.$refs[str]; // 获取要复制的元素
			  if (navigator.clipboard) {
				navigator.clipboard.writeText(copyElement.innerText); // 使用新版API直接写入剪贴板
				this.$message({message:'复制成功',type:'success'})
			  } else {
				let range = document.createRange();
				range.selectNodeContents(copyElement); // 选择要复制的节点内容
				
				window.getSelection().removeAllRanges(); // 清除之前的选区
				window.getSelection().addRange(range); // 添加新的选区
				
				try {
				  document.execCommand("copy"); // 执行复制命令
				} catch (err) {
				  console.error('无法复制', err); // 输出错误信息
				} finally {
				  window.getSelection().removeAllRanges(); // 移除选区
				}
			  }
		},
		handleAvatarSuccess(res, file) {
			this.upload_img = res.data.pic_path;
			// this.headimg = URL.createObjectURL(file.raw);
		},
		uploadPayInfo(){
			if(!this.upload_img){
				this.$message.error('请上传支付凭证')
				return;
			}
			savePaymentVoucher({
				plan_id:this.upload_plan_id,
				payment_voucher:this.upload_img
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message: '上传成功，正在处理支付结果，请勿发起重复支付', //提示的信息
						type: "warning",
						offset: 225,
						duration: 3000
					})
					this.upload_payment = false;
					this.refresh();
				}else{
					this.$message.error(res.message);
				}
			})
		},
		toPay(orderData){
			this.orderClickINfo = orderData
			OrderpayConfig({
				site_id:orderData.site_id
			})
			.then(res=>{
				if(res.code>=0){
					this.show_pay_info = res.data;
					this.show_pay_info.plan_time = orderData.plan_time;
					this.show_pay_info.plan_amount = orderData.plan_amount;
					this.show_pay_info.periods = orderData.periods;
					this.upload_img = '';
					this.upload_plan_id = orderData.plan_id ;
					this.upload_payment = true;
				}else{
					this.$message.error(res.message);
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
			// onlineinterface({
			// 	plan_id:orderData.plan_id,
			// })
			// .then(res=>{
			// 	if(res.code>=0){
			// 		this.heliPayInfo = res.data
			// 		this.upload_payment = true;
			// 	}else{
			// 		this.$message.error(res.message);
			// 	}
			// })
		},
		changeTimeLimit(e){
			if(e){
				this.start_time = e[0];
				this.end_time = e[1];
			}
		},
		handleClick(tab, event) {
			this.currentPage = 1;
			this.orderStatus = tab.name;
			this.refresh();
		},
		getOrderList() {
			OrderPayplanPageList({
				page: this.currentPage,
				page_size: this.pageSize,
				start_time:this.start_time,
				end_time:this.end_time,
				order_no:this.order_no,
			})
				.then(res => {
					let list = [];
					if (res.code == 0 && res.data) {
						list = res.data.list;
						this.total = res.data.count;
					}
					this.orderList = list;
					this.loading = false;
				})
				.catch(res => {
					this.loading = false;
				});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getOrderList();
		},
		operation(action, orderData) {
			let index = this.status;
			switch (action) {
				case 'orderPay': // 支付
					this.orderPay(orderData);
					break;
				case 'orderClose': //关闭
					this.orderClose(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'memberTakeDelivery': //收货
				console.log('9999')
					this.orderDelivery(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'trace': //查看物流
					this.$router.push({ path: '/member/logistics', query: { order_id: orderData.order_id } });
					break;
				case 'memberOrderEvaluation': //评价
					this.$router.pushToTab({ path: '/evaluate', query: { order_id: orderData.order_id } });
					break;
				case 'extendTakeDelivery': //延长收货
					this.orderExtendDelivery(orderData, () => {
						this.refresh();
					});
					break;
				case 'orderExaminePass':
					//审核通过
					this.ExaminePass(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'contractRefuse':
					//审核拒绝
					this.ExamineRefuse(orderData.order_id, () => {
						this.refresh();
					});
					break;
			}
		},
		orderDetail(data) {
			this.$router.push('/member/payment_detail?plan_id='+data.plan_id)
		},
		imageError(orderIndex, goodsIndex) {
			this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>
<style lang="scss">
	.add_dialog {
		.el-dialog__body{
			padding: 0;
			.add_content{
				padding: 8px 44px 41px;
				.tit{
					font-size: 15px;
					line-height: 22px;
					font-weight: 600;
					margin-bottom: 24px;
				}
				.condition{
					padding-left: 10px;
					.bottom_btn{
						display: flex;
						align-items: center;
						justify-content: center;
						.btn{
							margin-left: 30px;
							cursor: pointer;
							display: flex;
							align-items: baseline;
							justify-content: center;
							border-radius: 5px;
							background-color: #FF4646;
							color: #fff;
							font-size: 16px;
							line-height: 22px;
							padding: 10px 0;
							padding: 10px 16px;
							text-align: center;
							.tips{
								font-size: 14px;
								color: #fff;
								margin-left: 5px;
							}
						}
					}
					.condition_item{
						display: flex;
						align-items: center;
						margin-bottom: 20px;
						&.upload{
							align-items: flex-start;
						}
						.left{
							min-width: 105px;
							display: flex;
							align-items: center;
							color: #E02020;
							font-size: 12px;
							line-height: 16px;
							margin-right: 2px;
							margin-right: 10px;
							
							.name{
								margin-left: 5px;
								color: #222;
								font-size: 12px;
								line-height: 16px;
							}
						}
						.right{
							flex:1;
							font-size: 14px;
							line-height: 20px;
							.upload-img{
								line-height: 1;
								width: 200px;
								height:200px;
								.el-upload{
									width: 100%;
									height: 100%;
								}
								.add_img{
									border: 1px dashed #dfdfdf;
									padding: 20px 12px;
									display: flex;
									align-items: center;
									flex-direction: column;
									font-size: 14px;
									.el-icon-plus{
										margin-bottom: 10px;
										font-size: 16px;
									}
								}
								.img-wrap{
									width: 100%;
									height: 100%;
									img{
										display: block;
										border-radius: 5px;
										width: 100%;
										height: 100%;
										object-fit: contain;
									}
								}
							}
						}
					}
				}
				.btns{
					margin-top: 12px;
					display: flex;
					align-items: center;
					padding-left: 104px;
					.btn{
						cursor: pointer;
						margin-right: 24px;
						color: #333;
						font-size: 14px;
						line-height: 16px;
						padding: 9px 20px;
						border-radius: 2px;
						border: 1px solid #DBDCDC;
						&.save{
							background-color: #007AFF;
							color: #fff;
							border-color: #007AFF;
						}
					}
				}
			}
		}
	}
	.search_area{
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		>div{
			display: flex;
			align-items: center;
			margin-bottom: 12px;
		}
		.el-date-editor{
			border: 1px solid #E0E0E0;
			border-radius: 0;
			height: auto;
			padding: 8px 10px;
			.el-range-separator{
				line-height: 20px;
			}
			.el-input__icon{
				line-height: 1;
			}
			.el-range-input{
				border: none;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 0;
			}
		}
		input{
			border: 1px solid #E0E0E0;
			outline: none;
			color: #333;
			font-size: 14px;
			line-height: 20px;
			padding: 8px 12px;
			margin-right: 12px;
		}
		input::placeholder{
			color: #888;
			font-size: 14px;
			line-height: 20px;
		}
		.btn{
			color: #FFF;
			font-size: 12px;
			line-height: 16px;
			padding: 6px 18px;
			border-radius: 2px;
			background-color: #00A98F;
			margin-left: 10px;
			cursor: pointer;
		}
	}
</style>
<style lang="scss" scoped>
	
.bulk_box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-list {
	nav {
		overflow: hidden;
		padding: 10px;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 33%;
			}
			&:nth-child(2) {
				width: 16%;
			}
			
			&:nth-child(3) {
				width: 13%;
			}
			&:nth-child(4) {
				width: 13%;
			}
			&:nth-child(5) {
				width: 14%;
			}
			&:nth-child(6) {
				width: 10%;
			}
		}
	}
	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;
			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;

				.create-time {
					margin-right: 15px;
				}

				border-bottom: 1px solid #eeeeee;
				a {
					margin: 0 10px 0 20px;
				}
				.order-no{
					margin-right: 10px;
				}
				.order-type {
					margin-left: 15px;
				}
			}
		}
		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;
			li {
				float: left;
				min-height: 60px;
				// line-height: 60px;
				&:nth-child(1) {
					width: 33%;
					line-height: inherit;
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						margin-left: 70px;
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;
							&:hover {
								color: $base-color;
							}
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					width: 16%;
					color: #E62032;
				}
				// &:nth-child(3) {
				// 	width: 13%;
				// 	padding-right: 10px;
				// 	box-sizing: border-box;
				// }
				&:nth-child(3) {
					width: 13%;
					color: #E62032;
				}
				&:nth-child(4) {
					width: 13%;
					color: #E62032;
				}
				&:nth-child(5) {
					width: 14%;
					a {
						display: block;
					}
				}
				&:nth-child(6) {
					width: 10%;
					line-height: initial;
					button {
						margin-left: 0;
						margin-bottom: 10px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
}
.active-tab .title{
	color: #007AFF !important;
}
.tab-list{
	margin:0 0 15px 0;
	padding: 0 30px;
	display: flex;
	.tab-item{
		margin: 0 15px;
		margin-right: 30px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.title{
			line-height: 3;
			color: #000000;
			font-size: 18px;
		}
		.border-box{
			background-color: #007AFF;
			height: 2px;
			width: 50px;
		}
	}
}
.heli-tit{
	color:red !important;
	font-size:15px !important
}
.copy-btn{
	cursor: pointer;
	font-size: 10px;
	border: 1px solid #13a5ff;
	padding: 3px 2px;
	border-radius: 3px;
	color: #13a5ff;
	margin-left: 15px;
}
</style>
